/**
 * Font Styles:
 * Satoshi Light
 * Satoshi Light Italic
 * Satoshi Regular
 * Satoshi Italic
 * Satoshi Medium
 * Satoshi Medium Italic
 * Satoshi Bold
 * Satoshi Bold Italic
 * Satoshi Black
 * Satoshi Black Italic
**/

  @font-face {
    font-family: 'Satoshi-Light';
    src: url('../fonts/Satoshi-Light.otf') format('opentype'),;
         font-weight: 300;
         font-display: swap;
         font-style: normal;
  }
  
  
  @font-face {
    font-family: 'Satoshi-LightItalic';
    src: url('../fonts/Satoshi-LightItalic.otf') format('opentype');
         font-weight: 300;
         font-display: swap;
         font-style: italic;
  }
  
  
  @font-face {
    font-family: 'Satoshi-Regular';
    src: url('../fonts/Satoshi-Regular.otf') format('opentype');
         font-weight: 400;
         font-display: swap;
         font-style: normal;
  }
  
  
  @font-face {
    font-family: 'Satoshi-Italic';
    src: url('../fonts/Satoshi-Italic.otf') format('opentype');
         font-weight: 400;
         font-display: swap;
         font-style: italic;
  }
  
  
  @font-face {
    font-family: 'Satoshi-Medium';
    src: url('../fonts/Satoshi-Medium.otf') format('opentype');
         font-weight: 500;
         font-display: swap;
         font-style: normal;
  }
  
  
  @font-face {
    font-family: 'Satoshi-MediumItalic';
    src: url('../fonts/Satoshi-MediumItalic.otf') format('opentype');
         font-weight: 500;
         font-display: swap;
         font-style: italic;
  }
  
  
  @font-face {
    font-family: 'Satoshi-Bold';
    src: url('../fonts/Satoshi-Bold.otf') format('opentype');
         font-weight: 700;
         font-display: swap;
         font-style: normal;
  }
  
  
  @font-face {
    font-family: 'Satoshi-BoldItalic';
    src: url('../fonts/Satoshi-BoldItalic.otf') format('opentype');
         font-weight: 700;
         font-display: swap;
         font-style: italic;
  }
  
  
  @font-face {
    font-family: 'Satoshi-Black';
    src: url('../fonts/Satoshi-Black.otf') format('opentype');
         font-weight: 900;
         font-display: swap;
         font-style: normal;
  }
  
  
  @font-face {
    font-family: 'Satoshi-BlackItalic';
    src: url('../fonts/Satoshi-BlackItalic.otf') format('opentype');
         font-weight: 900;
         font-display: swap;
         font-style: italic;
  }
  
  