@import url("./../assets/fonts/satoshi/css/satoshi.css");

/* 
font-family : Satoshi-Light
font-family : Satoshi-LightItalic
font-family : Satoshi-Regular
font-family : Satoshi-Italic
font-family : Satoshi-Medium
font-family : Satoshi-MediumItalic
font-family : Satoshi-Bold
font-family : Satoshi-BoldItalic
font-family : Satoshi-Black
font-family : Satoshi-BlackItalic
*/

* {
  margin: 0;
  padding: 0;
  border: 0;
}

html {
  font-size: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Satoshi-Regular", "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media (max-width: 998px) {
  html {
    font-size: 80%;
  }
}
@media (max-width: 768px) {
  html {
    font-size: 75%;
  }
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #1b2749;
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #0057d9;
  border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  width: 30px;
}
